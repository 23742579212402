import https from "./https";
import store from "@/store";

const register = {
  fetchMe() {
    const organization = store.state.liffGeneral.orgCode;

    return https.get(`${organization}/liff/general/me`);
  },

  updateMe(params) {
    const organization = store.state.liffGeneral.orgCode;

    return https.put(`${organization}/liff/general/me`, params);
  },

  updateMeV2(params) {
    const organization = store.state.liffGeneral.orgCode;

    return https.put(`${organization}/liff/general/me-v2`, params);
  },

  updateMeViaProfilePlus(params) {
    const organization = store.state.liffGeneral.orgCode;

    return https.put(`${organization}/liff/general/me-via-profile-plus`, params);
  },
};

export default register;
