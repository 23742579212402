import https from "./https";

const organization = {
  getOrganizations(params) {
    const searchParams = new URLSearchParams(params);

    return https.get(`/admin/system-settings/organizations?${searchParams}`);
  },
  getOrganizationById(orgId) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }

    return https.get(`/admin/system-settings/organizations/${orgId}`);
  },
  get(org_code) {
    if (!org_code) {
      return Promise.reject("org_code not found.");
    }

    return https.get("/general/organizations/" + org_code);
  },
  getMyOrganizations() {
    return https.get("/admin/me/organizations");
  },
  getThemeConfig(org_code) {
    if (!org_code) {
      return Promise.reject("org_code not found.");
    }

    return https.get("/general/organizations/" + org_code + "/theme-config");
  },
  setCurrentOrg(org_id) {
    return https.put("/admin/me/set-current-org", {
      org_id: org_id,
    });
  },
  createOrganization(organization) {
    return https.post("/admin/system-settings/organizations", organization);
  },
  updateOrganization(orgId, organization) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }

    return https.put(
      `/admin/system-settings/organizations/${orgId}`,
      organization
    );
  },
  deleteOrganization(orgId) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }

    return https.delete(`/admin/system-settings/organizations/${orgId}`);
  },
  resetOrg(orgId) {
    return https.post(`/admin/system-settings/organizations/${orgId}/reset`);
  },
  setDefaultRole(orgId) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }

    return https.post(
      `/admin/system-settings/organizations/${orgId}/set-default-role`,
      organization
    );
  },
  getModuleConfig(orgId, params) {
    return https.get(`/admin/organizations/${orgId}/module-config`, { params });
  },
  getOrganizationByShoplineId(params) {
    return https.get(`/admin/organizations/info-by-shopline`, { params });
  }
};

export default organization;
