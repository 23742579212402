import router from '@/router'

export default {
  namespaced: true,
  state: {
    history: [],
    intended: null,
    queryParams: {},
  },
  getters: {
    from(state) {
      return state.history[state.history.length - 2]
    },
    hasIntended(state) {
      return !!state.intended
    },
    queryParams(state) {
      return state.queryParams
    },
  },
  mutations: {
    PUSH(state, route) {
      if (state.history.length && state.history[state.history.length - 1] === route) {
        return
      }

      state.history.push(route)
    },
    SET_INTENDED(state, route) {
      state.intended = route
    },
    SET_QUERY_PARAMS(state, params) {
      state.queryParams = params
    },
  },
  actions: {
    intended({ state, commit }, fallback = '/') {
      if (!state.intended) {
        return router.push(fallback)
      }

      const intended = state.intended

      commit('SET_INTENDED', null)

      if (intended.startsWith('http')) {
        return window.location = intended
      }

      return router.push(intended)
    },
  },
}
