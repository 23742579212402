import store from '@/store'
import router from '@/router'

/**
 * middleware: loadModules
 *
 * 用途： 把 modules 先讀取到 VUEX 供後續使用
 *
 * visit liff page -> loadModules middleware -> mount liff page
 */
export default async function loadModules({ next, to }) {
  let modules = to.matched.filter((record) => record.meta.modules).map((record) => record.meta.modules);

  modules = [].concat.apply([], modules)
  modules = [...new Set(modules)]

  for (let i = 0; i < modules.length; i ++) {
    let moduleCode = modules[i]
    try {
      await store.dispatch('liffModule/fetchModule', moduleCode)
    } catch (error) {
      console.error('fetchModule failed', error)
      const orgCode = to.params.org_code || to.params.orgCode
      return router.push({ name: 'LiffModuleNotYet', params: { orgCode: orgCode } })
    }
  }

  return next()
}
