import organization from "@/apis/organization";

export default {
  namespaced: true,
  state: {
    modules: [],
  },
  getters: {
    getModule: (state) => (code) => {
      return state.modules.find(module => module.module_code === code)
    },
    getConfig: (state, getters) => (code, configKey) => {
      let module = getters.getModule(code)
      if (!module) return undefined

      const keys = configKey.split("."); // 將 key 字串以 . 分隔成陣列
      return keys.reduce((obj, key) => obj ? obj[key] : undefined, module.module_config);
    },
  },
  mutations: {
    SET_MODULE(state, module) {
      if (!state.modules.find((m) => m.module_code === module.module_code)) {
        state.modules.push(module)
      }
    }
  },
  actions: {
    async fetchModule (context, params) {
      if (context.getters.getModule(params.code)) {
        console.debug(`module loaded`, params.code)
        return
      }
      try {
        const { data } = await organization.getModuleConfig(params.org_id, { module_code: params.code });
        context.commit('SET_MODULE', { module_code: params.code, module_config: data })
        console.debug('fetch module', data.data)
      } catch (error) {
        context.commit('SET_MODULE', { module_code: params.code, module_config: {} })
      }

    },
  },
};
