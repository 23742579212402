import pointApi from '@/apis/liff/v2/point'

export default {
  namespaced: true,
  state: {
    availablePoints: '-',
    expiringSoonPoints: null,
    expiringSoonPointsDate: null,
  },
  getters: {
    availablePoints({availablePoints}) {
      return availablePoints;
    },
    expiringSoonPoints({expiringSoonPoints}) {
      return expiringSoonPoints;
    },
    expiringSoonPointsDate({expiringSoonPointsDate}) {
      return expiringSoonPointsDate;
    }
  },
  mutations: {
    SET_AVAILABLE_POINTS(state, point) {
      state.availablePoints = point;
    },
    SET_EXPIRING_SOON_POINTS(state, point) {
      state.expiringSoonPoints = point;
    },
    SET_EXPIRING_SOON_POINTS_DATE(state, date) {
      state.expiringSoonPointsDate = date;
    },
  },
  actions: {
    async fetchAvailablePoints({commit}, { eventCode }) {
      commit('SET_AVAILABLE_POINTS', await pointApi.getAvailablePoints(eventCode))
    },
    async fetchExpiringSoonPoints({commit}, { eventCode }) {
      const info = await pointApi.getExpiringSoonPoints(eventCode)
      commit('SET_EXPIRING_SOON_POINTS', info.point)
      commit('SET_EXPIRING_SOON_POINTS_DATE', info.expired_at)
    }
  },
};
